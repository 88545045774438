import React, { createContext, useState } from "react";

const Mycontextprovider = createContext()

export function Globaldataprovider({ children }) {
    const [Isdisconnected,setIsdisconnected] =useState(false)
    const [connectedAddress,setconnectedAddress] = useState()
    const [updateperformTask,setupdateperformTask] = useState(0)
    const [homeORreferral,sethomeORreferral] = useState(false)
    const [backReferral,setbackReferral] = useState(false)
    const [metaMaskAddress,setmetaMaskaddress]=useState()
    const [isMetaMaskConnected,setIsMetaMaskConnected]=useState(false)
    const [kaanchBalancePr,setkaanchBalancePr]=useState()
    const [chainIDpr,setChainIDPr]=useState()
    const [connectPopUp,setconnectPopUp]=useState(false)
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [sidebaropen,setSidebaropen]=useState(false)
   
    const SidebarToggleMenu_set=(param)=>{
        setSidebaropen(param)
    }
   
    const StakePopup_seted=(params)=>{
        setconnectPopUp(params)
    }
    
    const OpenSidebar = (param) => {
      setOpenSidebarToggle(!param);
    };
  

    function metaMaskChainId_seted(params){
        setChainIDPr(params)
    }

    function metaMaskConnected_Seted(params){
        setmetaMaskaddress(params)
    }
    function kaanchBalance_seted(params){
        setkaanchBalancePr(params)
    }
    function metaMask_Isdisconnected_seted(params){
        setIsMetaMaskConnected(params)
    }
    function Isdisconnected_seted(params) {
        setIsdisconnected(params)
    }
    function Connecting_Owner_Address(params) {
        setconnectedAddress(params)
    }

    function IshomeORreferral(params) {
        sethomeORreferral(params)
    }

    const performTask = (paramsValue) => {
  
        setupdateperformTask(paramsValue);
     
    };

    return (
        <Mycontextprovider.Provider value={{
            Isdisconnected_seted,Isdisconnected,
            backReferral,
            Connecting_Owner_Address,connectedAddress,
            IshomeORreferral,homeORreferral,
            performTask,updateperformTask,metaMaskConnected_Seted,metaMaskAddress,metaMask_Isdisconnected_seted,kaanchBalancePr,kaanchBalance_seted,
            metaMaskChainId_seted,chainIDpr,OpenSidebar,openSidebarToggle,StakePopup_seted,connectPopUp, SidebarToggleMenu_set,sidebaropen
            
        }}>{children}</Mycontextprovider.Provider>
    )

}
export default Mycontextprovider;



