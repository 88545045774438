import React, { useContext, useEffect, useRef, useState } from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Mycontextprovider from "../ContextProvider";
const Sidebar = () => {
  const { SidebarToggleMenu_set,sidebaropen}=useContext(Mycontextprovider);
  const location = useLocation();
  const closeToggle=()=>{
    SidebarToggleMenu_set(false)
  }
 
  const [activeMenu, setActiveMenu] = useState("stake");
  

  useEffect(() => {
    // Get the current route and set the active menu accordingly
    // const currentRoute = window.location.pathname;
   const currentRoute = location.pathname;
    switch (currentRoute) {
      case "/":
        setActiveMenu("stake");
        break;
      case "/wrap":
        setActiveMenu("wrap");
        break;
      case "/claim":
        setActiveMenu("claim");
        break;
      default:
        setActiveMenu("stake");
    }
  },[location]);

  /**Outside */
const sidebarContainerRef=useRef(null)
const handleOutsideClick = (event) => {
  if (
    sidebarContainerRef.current &&
    !sidebarContainerRef.current.contains(event.target)
  ) {
   
    closeToggle();
  }
};


useEffect(() => {
  // Add mousedown event listener to handle clicks outside the sidebar
  document.addEventListener("mousedown", handleOutsideClick);

  // Cleanup the event listener on component unmount
  return () => {
    document.removeEventListener("mousedown", handleOutsideClick);
  };
}, [sidebarContainerRef]);
  
  return (
    <div>
      <div className={!sidebaropen?"sidebar ":"sidebar sidebar-open"} ref={sidebarContainerRef}>
        <div className="top-side">
        <div className="logo">
          <img
            className="img-logo-side"
            src="https://app.keth.com/Image/keth-website.png"
          />
        </div>
        <div style={{marginTop:"5px", fontSize:"30px"}} className="close" onClick={closeToggle}> 
          <span class="material-symbols-outlined" style={{color:"red"}}>close</span>
        </div>
        </div>
        <div className="sidebar-menu">
          {/* <p className="sidebar-menu-heading">Liquid Restake</p> */}
          <Link to="/">
        
            <div className={  `sidebar-menu-option ${
              activeMenu === "stake" ? "acive-color" : ""
            }`}>
              <span class="material-symbols-outlined">range_hood</span>
              <div className="sidebar-menu-option-name">Stake</div>
            </div>
          </Link>
          <Link to="/wrap">
            {/* <div className="sidebar-menu-option"> */}
            <div className={  `sidebar-menu-option ${
              activeMenu === "wrap" ? "acive-color" : ""
            }`}>
              <span class="material-symbols-outlined">wrap_text</span>
              <div className="sidebar-menu-option-name">Wrap</div>
            </div>
          </Link>
          <Link to="/claim">
            <div  className={  `sidebar-menu-option ${
              activeMenu === "claim" ? "acive-color" : ""
            }`}>
              <span class="material-symbols-outlined">assignment</span>
              <div className="sidebar-menu-option-name">Claim</div>
            </div>
          </Link>
          {/* <a href="#">
            <div className="sidebar-menu-option">
              <span class="material-symbols-outlined">range_hood</span>
              <div className="sidebar-menu-option-name">Restake</div>
            </div>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
