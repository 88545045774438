import React, { useContext, useEffect, useState } from "react";
import "./Wrap.css";
import Mycontextprovider from "../ContextProvider";
const Wrap = () => {
  const { Isdisconnected, connectedAddress, kaanchBalance_seted } =
    useContext(Mycontextprovider);

  const [wrapActiveBtn, setWrapActiveBtn] = useState(false);
  const [InputKETH, setInputKETH] = useState();
  const [tokenval, setTokenval] = useState();
  const [Hash, setHash] = useState();
  const [KaanchAllBalance, setKaanchAllBalance] = useState();
  const minPriceVal = 0.01;
  const maxPriceVal = 100000;

  const swapWrapPage = () => {
    setWrapActiveBtn(!wrapActiveBtn);
  };

  const wrap = async () => {
    try {
      if (InputKETH) {
        const result = await window.kaanch.sendKaanch(
          InputKETH,
          "0x0000000000000000000000000000000000000000"
        );
        setHash(result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const unwrap = async () => {
    const result = await window.kaanch.sendToken(
      InputKETH,
      "0x0000000000000000000000000000000000000000",
      "K967A75827E3E9de311Eb929BDc08865031D43d28"
    );
    setHash(result);
  };
  useEffect(() => {
    if (connectedAddress) {
      CHECK_THE_WALLET_BALANCE(connectedAddress);
    }
  }, [connectedAddress, Isdisconnected]);

  const CHECK_THE_WALLET_BALANCE = async (connectedAddress) => {
    const allbalance = await fetch("https://testnet-rpc.kaanch.network/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        method: "kaanch_allBalance",
        params: [`${connectedAddress}`, "latest"],
        id: 1,
      }),
    });
    let data2 = await allbalance.json();
    setKaanchAllBalance(Number(data2?.result?.balance) / 10 ** 18);
    kaanchBalance_seted(Number(data2.result.balance) / 10 ** 18);
    let newdata = [...data2.result.contracts];
    let data5 = newdata.filter(function (caaavalue) {
      return caaavalue.contract === "K77c89afBe78cc48f9d5e0bba2b1C7e5944fBb788";
    });
    if (data5[0]?.balance) {
      setTokenval(Number(data5[0]?.balance) / 10 ** 18);
    } else {
      setTokenval(0);
    }
  };
  const max_keth = () => {
    setInputKETH(KaanchAllBalance);
  };
  const max_wketh = () => {
    setInputKETH(tokenval);
  };

  return (
    <div className="main-center-wrap">
      <div className="main-center-container-wrap">
        <div className="main-center-container-child-wrap">
          {/* for the wrapping page */}
          {wrapActiveBtn === false && (
            <div className="main-center-container-child-card-wrap">
              <h3>Wrap KETH</h3>
              <div className="second-main-container-child-one-five-wrap">
                <div
                  className="second-main-container-child-one-five-first-wrap"
                  style={{ color: "#696868" }}
                >
                  Your Send
                </div>
                <div className="second-main-container-child-one-five-second-wrap">
                  <input
                    className="second-main-container-child-one-five-second-input-wrap"
                    placeholder="0"
                    value={InputKETH}
                    onChange={(e) => {
                      setInputKETH(e.target.value);
                    }}
                  ></input>
                  <div className="second-main-container-child-one-five-second-dropdown-wrap">
                    <div className="second-main-container-child-one-five-second-dropdown-drp-wrap">
                      KETH
                    </div>
                  </div>
                </div>
                <div className="second-main-container-child-one-five-third-wrap">
                  {
                    connectedAddress && (<>
                    <div
                    style={{ color: "#8a0aaa" }}
                    className="max-btn"
                    onClick={max_keth}
                  >
                    Max
                  </div>
                  <div className="balance">Balance: {KaanchAllBalance}</div>
                    </>)
                  }
                </div>
              </div>
              {/* for swap */}
              <div className="second-main-container-child-one-six-wrap">
                
                <img
                  className="swap-btn active"
                  onClick={swapWrapPage}
                  src={`${process.env.PUBLIC_URL}/Image/refresh.png`}
                  alt="kaanch"
                />
              </div>

              {/* <================================for the second input start */}
              <div className="second-main-container-child-one-five-wrap">
                <div
                  className="second-main-container-child-one-five-first-wrap"
                  style={{ color: "#696868" }}
                >
                  You Recieve
                </div>
                <div
                  className="second-main-container-child-one-five-second-wrap"
                  style={{ marginBottom: "8px" }}
                >
                  <input
                    className="second-main-container-child-one-five-second-input-wrap"
                    placeholder="0"
                  ></input>
                  <div className="second-main-container-child-one-five-second-dropdown-wrap">
                    <div className="second-main-container-child-one-five-second-dropdown-drp-wrap">
                      WKETH
                    </div>
                  </div>
                </div>
                <div className="second-main-container-child-one-five-third-wrap">
                  {/* <div style={{ color: "#8a0aaa" }}>Max</div>
                <div>Balance: 0000000</div> */}
                </div>
              </div>

              {/* for the second input end=======================> */}
              <div
                className={
                  maxPriceVal >= InputKETH &&
                  InputKETH >= minPriceVal &&
                  KaanchAllBalance >= InputKETH
                    ? "wrapUnwrap"
                    : "wrapUnwrapDisable"
                }
                onClick={wrap}
              >
                Wrap
              </div>

              {/* For the transaction */}
              {Hash && (
                <div className="transaction">
                  {Hash && Hash === "Insufficient Funds" && (
                    <span style={{ color: "#b65e5e", fontWeight: "bold" }}>
                      Insufficient Funds
                    </span>
                  )}
                  {Hash && Hash === "User Rejected" && (
                    <span style={{ color: "red" }}>
                      User Rejected Transaction
                    </span>
                  )}
                  {Hash && Hash.length === 66 && (
                    <>
                      <span>Your Transaction is Successfull: </span>
                      <span
                        className="wallet-msg"
                        style={{
                          marginLeft: "6px",
                          color: "black",
                          background: "#06f7f7",
                          cursor: "pointer",
                          borderRadius: "3px",
                          padding: "4px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          window.open(
                            `https://www.kaanchscan.com/tx/${Hash}`,
                            "_blank"
                          )
                        }
                      >
                        {Hash.slice(0, 4)}...
                        {Hash.slice(-4)}{" "}
                        <span
                          class="material-symbols-outlined"
                          style={{
                            fontSize: "18px",
                            color: "black",
                            fontWeight: "800",
                          }}
                        >
                          north_east
                        </span>
                      </span>
                    </>
                  )}
                  {Hash === "Pleading" && <span>Pleading</span>}
                </div>
              )}
            </div>
          )}

          {wrapActiveBtn === true && (
            <div className="main-center-container-child-card-wrap">
              <h3>Unwrap KETH</h3>
              <div className="second-main-container-child-one-five-wrap">
                <div
                  className="second-main-container-child-one-five-first-wrap"
                  style={{ color: "#696868" }}
                >
                  Your Send
                </div>
                <div className="second-main-container-child-one-five-second-wrap">
                  <input
                    className="second-main-container-child-one-five-second-input-wrap"
                    value={InputKETH}
                    placeholder="0"
                    onChange={(e) => {
                      setInputKETH(e.target.value);
                    }}
                  ></input>
                  <div className="second-main-container-child-one-five-second-dropdown-wrap">
                    <div className="second-main-container-child-one-five-second-dropdown-drp-wrap">
                      WKETH
                    </div>
                  </div>
                </div>
                <div className="second-main-container-child-one-five-third-wrap">
                  {connectedAddress && (<>
                    <div
                    style={{ color: "#8a0aaa" }}
                    className="max-btn"
                    onClick={max_wketh}
                  >
                    Max
                  </div>
                  <div className="balance">Balance: {tokenval}</div>
                  </>)}
                </div>
              </div>
              {/* for swap */}
              <div className="second-main-container-child-one-six-wrap">
                
                <img
                  className="swap-btn active"
                  onClick={swapWrapPage}
                  src={`${process.env.PUBLIC_URL}/Image/refresh.png`}
                  alt="kaanch"
                />
              </div>

              {/* <================================for the second input start */}
              <div className="second-main-container-child-one-five-wrap">
                <div
                  className="second-main-container-child-one-five-first-wrap"
                  style={{ color: "#696868" }}
                >
                  You Recieve
                </div>
                <div
                  className="second-main-container-child-one-five-second-wrap"
                  style={{ marginBottom: "8px" }}
                >
                  <input
                    className="second-main-container-child-one-five-second-input-wrap"
                    placeholder="0"
                  ></input>
                  <div className="second-main-container-child-one-five-second-dropdown-wrap">
                    <div className="second-main-container-child-one-five-second-dropdown-drp-wrap">
                      KETH
                    </div>
                  </div>
                </div>
                <div className="second-main-container-child-one-five-third-wrap">
               
                </div>
              </div>
              {/* for the second input end=======================> */}

              <div
                className={
                  maxPriceVal >= InputKETH &&
                  InputKETH >= minPriceVal &&
                  tokenval >= InputKETH
                    ? "wrapUnwrap"
                    : "wrapUnwrapDisable"
                }
              >
                Unwrap
              </div>

              {/* For the transaction */}
              {Hash && (
                <div className="transaction">
                  {Hash && Hash === "Insufficient Funds" && (
                    <span style={{ color: "#b65e5e", fontWeight: "bold" }}>
                      Insufficient Funds
                    </span>
                  )}
                  {Hash && Hash === "User Rejected" && (
                    <span style={{ color: "red" }}>
                      User Rejected Transaction
                    </span>
                  )}
                  {Hash && Hash.length === 66 && (
                    <>
                      <span>Your Transaction is Successfull: </span>
                      <span
                        className="wallet-msg"
                        style={{
                          marginLeft: "6px",
                          color: "black",
                          background: "#06f7f7",
                          cursor: "pointer",
                          borderRadius: "3px",
                          padding: "4px",
                        }}
                        onClick={() =>
                          window.open(
                            `https://www.kaanchscan.com/tx/${Hash}`,
                            "_blank"
                          )
                        }
                      >
                        {Hash.slice(0, 4)}...
                        {Hash.slice(-4)}
                      </span>
                    </>
                  )}
                  {Hash === "Pleading" && <span>Pleading</span>}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Wrap;
