import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Stake from "./components/Stake";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Wrap from "./components/Wrap";
import Claim from "./components/Claim";
import { Globaldataprovider } from "./ContextProvider";
function App() {
  return (
    <div className="home">

      <Globaldataprovider>
        <Router>
          <Sidebar/>
          <Header/>
          <Routes>
            <Route path="/" element={<Stake/>} />
            <Route path="/wrap" element={<Wrap/>} />
            <Route path="/claim" element={<Claim/>} />
          </Routes>
        </Router>
      </Globaldataprovider>
    </div>
  );
}

export default App;
