import React from "react";
import "./Header.css";
import Mycontextprovider from "../ContextProvider";
import { useState, useEffect, useContext, useRef } from "react";
import { BsTelegram, BsTwitterX } from "react-icons/bs";
import { ImTelegram } from "react-icons/im";
import { RiDiscordLine } from "react-icons/ri";

import { SiGoogledocs } from "react-icons/si";
import { TbAlignBoxCenterMiddleFilled } from "react-icons/tb";
import { FcDisclaimer } from "react-icons/fc";
const Header = () => {
  
  const {
    Isdisconnected_seted,
    Isdisconnected,
    Connecting_Owner_Address,
    connectedAddress,
    metaMaskConnected_Seted,
    metaMask_Isdisconnected_seted,
    kaanchBalance_seted,
    metaMaskChainId_seted,
    SidebarToggleMenu_set
  } = useContext(Mycontextprovider);
  const closeToggle=()=>{
    SidebarToggleMenu_set(true)
  }
 
  const [metaMaskAddress, setmetaMaskaddress] = useState();
  const [MetaMaskAccountConnected, setMetaMaskAccountConnected] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [copied, setCopied] = useState(true);
  const [copiedMeta, setCopiedmeta] = useState(true);
  const [chainID, setChainID] = useState("");
  const dropdownRef = useRef(null);
  const [kaanch_address, setkaanch_address] = useState();
  const [kaanchobjnotfound, setkaanchobjnotfound] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.kaanch) {
        setkaanchobjnotfound(false);
        window.kaanch.isConnected().then((connected) => {
          if (connected) {
            window.kaanch.account().then((account) => {
              if (account.length === 42) {
                Connecting_Owner_Address(account);
                Isdisconnected_seted(true);

                setkaanch_address(account);
              }
            });
          }
        });
      } else {
        console.error("kaanch object not found on window");
        setkaanchobjnotfound(true);
      }
    }, 500);

    return () => clearInterval(timer);
  });

  async function connecting() {
    try {
      if (window.kaanch) {
        if (window.kaanch.connect) {
          const Connect = await window.kaanch.connect();
          if (Connect.length === 42) {
            Connecting_Owner_Address(Connect);
            Isdisconnected_seted(true);

            setkaanch_address(Connect);
          }
        }
      } else {
        console.error("kaanch object not found on window");
      }
    } catch (error) {
      console.error("Error connecting:", error);
    }
  }

  const disconnecting = async () => {
    try {
      await window.kaanch.disconnect();
      Connecting_Owner_Address(null);
      Isdisconnected_seted(false);
      setkaanch_address(null);
    } catch (error) {
      console.error("Error connecting:", error);
    }
  };

  const handleCopyClick = () => {
    const textToCopy = connectedAddress;

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select the text within the textarea
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(false);
    setTimeout(() => {
      setCopied(true);
    }, 1000);
  };

  const handleCopyClickMetamask = () => {
    const textToCopy = metaMaskAddress;

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    // Select the text within the textarea
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedmeta(false);
    setTimeout(() => {
      setCopiedmeta(true);
    }, 1000);
  };
  //   for the metamask connect
  const connectToMetaMask = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        setmetaMaskaddress(accounts[0]);
        metaMaskConnected_Seted(accounts[0]);
        setMetaMaskAccountConnected(true);
        metaMask_Isdisconnected_seted(true);
      } else {
        alert("MetaMask not found. Please install it.");
      }
    } catch (error) {
      console.error(error);
      alert("Error connecting to MetaMask.");
    }
  };

  //   disconnecting metamask
  const disconnectFromMetamaks = async () => {
    await window.ethereum.request({
      method: "wallet_revokePermissions",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    setmetaMaskaddress("");
    setMetaMaskAccountConnected(false);
    metaMaskConnected_Seted("");
    metaMask_Isdisconnected_seted(false);
  };
  useEffect(() => {
    const checkMetaMaskConnection = () => {
      if (window.ethereum) {
    
        window.ethereum
          .request({ method: "eth_accounts" }) // Requesting user permission to access accounts
          .then((accounts) => {
            if (accounts.length > 0) {
              // MetaMask is connected and accounts are available
              setmetaMaskaddress(accounts[0]);
              metaMaskConnected_Seted(accounts[0]);
              setMetaMaskAccountConnected(true);
              metaMask_Isdisconnected_seted(true);
            } else {
              console.error("No accounts found in MetaMask");
            }
          })
          .catch((error) => {
            console.error("Error requesting MetaMask accounts:", error);
          });
      } else {
        // MetaMask is not installed
        console.error("MetaMask not found");
      }
    };

    // Check MetaMask connection on component mount
    checkMetaMaskConnection();

    // Check MetaMask connection periodically
    const timer = setInterval(() => {
      checkMetaMaskConnection();
    }, 500);

    // Cleanup interval
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const body = document.body;
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
        body.style.overflow = "auto";
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
    // const body = document.body;
    // setDropdownOpen((prev) => {
    //   if (!prev) {
    //     // Disable scrolling when dropdown is opened
    //     body.style.overflow = "hidden";
    //   } else {
    //     // Enable scrolling when dropdown is closed
    //     body.style.overflow = "auto";
    //   }
    //   return !prev;
    // });
  };

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0xaa36a7" }],
      });
      console.log("Switched network successfully.");
    } catch (error) {
      console.error("Error switching network:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const fetchChainID = async () => {
        try {
          const chainId = await window.ethereum.request({
            method: "eth_chainId",
            params: [],
          });
          setChainID(chainId);
          metaMaskChainId_seted(chainID);
        } catch (error) {
          console.error("Error fetching chain ID:", error);
        }
      };
      fetchChainID();
    }, 500);

    return () => clearInterval(interval);
  }, [chainID]);

  useEffect(() => {
    if (connectedAddress) {
      CHECK_THE_WALLET_BALANCE();
    }
  }, [connectedAddress, Isdisconnected]);

  const CHECK_THE_WALLET_BALANCE = async () => {
    const allbalance = await fetch("https://testnet-rpc.kaanch.network/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        method: "kaanch_allBalance",
        params: [connectedAddress, "latest"],
        id: 1,
      }),
    });
    let data2 = await allbalance.json();

    kaanchBalance_seted(Number(data2.result.balance) / 10 ** 18);
  };

  function link_into_kaanch_extention() {
    window.open("https://download.kaanch.link/", "_blank");
  }

  return (
    <div>
      <div className="header-parent">
        <div className="header-left">
          <span class="material-symbols-outlined" onClick={closeToggle}>menu</span>
          <div className="logo">
            <img
              className="img-logo-nav"
              src="https://app.keth.com/Image/keth-website.png"
            />
          </div>
        </div>
        <div className="header-right">
          <div className=" header-right-child">
            {MetaMaskAccountConnected ? (
              <>
                {MetaMaskAccountConnected === true && chainID == "0xaa36a7" && (
                  <div className="header-right-child-one">
                    {MetaMaskAccountConnected && (
                      <>
                        <span>
                          <img
                            className="metmaskHeader-img"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/2048px-MetaMask_Fox.svg.png"
                            alt="kaanch"
                          />
                        </span>
                        <span className="">
                          {metaMaskAddress.slice(0, 4)}..
                          {metaMaskAddress.slice(-4)}
                        </span>
                        <span
                          className="material-symbols-outlined copyBtn "
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={handleCopyClickMetamask}
                        >
                          {copiedMeta ? "content_copy" : "done"}
                        </span>
                        {/* for disconnecting */}
                        <span
                          onClick={disconnectFromMetamaks}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            className="darkWhite"
                            src={`${process.env.PUBLIC_URL}/Image/disconnectRed.png`}
                            alt="kaanch"
                          />
                        </span>
                      </>
                    )}
                  </div>
                )}
                {MetaMaskAccountConnected === true &&
                  chainID!=="0xaa36a7"&& (
                    <div
                      className="header-right-child-one"
                      style={{ cursor: "pointer" }}
                      onClick={switchNetwork}
                    >
                      Switch to Sepolia
                    </div>
                  )}
              </>
            ) : (
              <div
                className="header-right-child-one"
                style={{ cursor: "pointer" }}
                onClick={connectToMetaMask}
              >
                connect to metamask
              </div>
            )}

            <div className="header-right-child-two" >
              {kaanchobjnotfound ? (
                <>Download Kaanch</>
              ) : (
                <>
                  {kaanch_address && Isdisconnected ? (
                    <>
                      <span>
                        <img
                          className="metmaskHeader-img"
                          style={{ width: "14px", height: "12px" }}
                          src={`${process.env.PUBLIC_URL}/Image/6.png`}
                          alt="kaanch"
                        />
                      </span>
                      <span className="">
                        {kaanch_address.slice(0, 4)}..
                        {kaanch_address.slice(-4)}
                      </span>
                      <span
                        className="material-symbols-outlined copyBtn "
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={handleCopyClick}
                      >
                        {copied ? "content_copy" : "done"}
                      </span>
                      <span
                        onClick={disconnecting}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="darkWhite"
                          src={`${process.env.PUBLIC_URL}/Image/disconnectRed.png`}
                          alt="kaanch"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ cursor: "pointer" }} onClick={connecting}>Connect Kaanch</span>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <button className="three-dot" onClick={toggleDropdown}>
            <span class="material-symbols-outlined">more_vert</span>
          </button>
          {dropdownOpen && (
            <>
            <div className="blur-background">{/*Drop down outside click */}</div>
            <div className="dropdown-content" ref={dropdownRef}>
                <a href="http://www.twitter.com/">
                <span >
                  
                    <span><BsTwitterX  className="drop-icon_symbol"/></span>
                  <span style={{position:"relative",bottom:"4px",left:"3px"}}>Twitter</span>
                </span>
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "14px"}}
                >
                  arrow_forward_ios
                </span>
              </a>
              <a href="#section2">
                <span >
                  
                    <span><RiDiscordLine  className="drop-icon_symbol"/></span>
                  <span style={{position:"relative",bottom:"4px",left:"3px"}}>Discord</span>
                </span>
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "14px"}}
                >
                  arrow_forward_ios
                </span>
              </a>
              <a href="#section3">
                <span >
                  
                    <span><BsTelegram  className="drop-icon_symbol"/></span>
                  <span style={{position:"relative",bottom:"4px",left:"3px"}}>Tellegram</span>
                </span>
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "14px"}}
                >
                  arrow_forward_ios
                </span>
              </a>
              <a href="#section4">
                <span >
                  
                    <span><SiGoogledocs  className="drop-icon_symbol"/></span>
                  <span style={{position:"relative",bottom:"4px",left:"3px"}}>Docs</span>
                </span>
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "14px"}}
                >
                  arrow_forward_ios
                </span>
              </a>
              <a href="#section5">
                <span >
                  
                    <span><TbAlignBoxCenterMiddleFilled  className="drop-icon_symbol"/></span>
                  <span style={{position:"relative",bottom:"4px",left:"3px"}}>Terms and Service</span>
                </span>
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "14px"}}
                >
                  arrow_forward_ios
                </span>
              </a>
              <a href="#section6">
                <span >
                  
                    <span><FcDisclaimer  className="drop-icon_symbol"/></span>
                  <span style={{position:"relative",bottom:"4px",left:"3px"}}>Risc and disclaimer</span>
                </span>
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "14px"}}
                >
                  arrow_forward_ios
                </span>
              </a>
            </div>
            </>
           
          )}
          {/* for drop down */}
          {/* <div className="three-dot-dropdown"></div>
          <div className="dropDownParent">
            <div className="dropDownParent">
            
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
