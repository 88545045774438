import React, { useContext } from "react";
import "./Claim.css";
import Mycontextprovider from "../ContextProvider";

const Claim = () => {
  const { connectedAddress,setkaanch_address,Isdisconnected_seted,Connecting_Owner_Address } = useContext(Mycontextprovider);
  async function connecting() {
    try {
      if (window.kaanch) {
        if (window.kaanch.connect) {
          const Connect = await window.kaanch.connect();
          if (Connect.length === 42) {
            Connecting_Owner_Address(Connect);
            Isdisconnected_seted(true);

            setkaanch_address(Connect);
          }
        }
      } else {
        console.error("kaanch object not found on window");
      }
    } catch (error) {
      console.error("Error connecting:", error);
    }
  }

  
  return (
    <>
      <div className="main-center-claim">
        <div className="main-center-container-claim">
          <div className="main-center-container-child-claim">
            <>
            <div className={`main-center-container-child-card-claim ${connectedAddress ? '' : 'blurClaim'}`}>
              {/* <===================First div start=============> */}
              <div className="fisrt-stake-container-claim">
                <div className="fisrt-stake-container-claim-one">
                  <div className="firstsection">
                    <h3 style={{ color: "black", fontWeight: "800" }}>
                      751,589,853.75
                    </h3>
                    <div style={{ color: "black", fontWeight: "600" }}>
                      KT points earned on Keth
                    </div>
                  </div>
                  <div className="secondsection">
                    <img
                      className="img-one"
                      src="https://app.keth.com/Image/kaanch.svg"
                    />
                  </div>
                </div>
                <div className="fisrt-stake-container-claim-two">
                  <div className="firstsection">
                    <h3 style={{ color: "black", fontWeight: "800" }}>
                      751,589,853.75
                    </h3>
                    <div style={{ color: "black", fontWeight: "600" }}>
                      KT points earned on Keth
                    </div>
                  </div>
                  <div className="secondsection">
                    <img
                      className="img-one"
                      src="https://app.keth.com/Image/kaanch.svg"
                    />
                  </div>
                </div>
              </div>
              {/* <=============Second div start */}
              <div className="sec-container-claim">
                <h2>Claim $KNCH</h2>
                <div className="sec-container-claim-two">
                  <div>Claimable KETH Points</div>
                  <div>0 KT Points</div>
                </div>
                <div className="sec-container-claim-three">
                  <div>Fee</div>
                  <div>0 KNCH</div>
                </div>
                <div className="sec-container-claim-four">
                  Kaanch will be available to Claim 10 days after Unstaking
                </div>
                <div className="sec-container-claim-five">Claim KETH</div>
              </div>
              {/* <===============Third div start=======> */}
              <div className="third-container-claim">
                <div className="third-container-claim-one">My Claim KETH</div>
                <h1>0 KETH</h1>
                <h4>My KETH Points:</h4>
                <div className="third-container-claim-four">
                  <div>Converted</div>
                  <div>0 KETH Points</div>
                </div>
                <div className="third-container-claim-four">
                  <div>Converted</div>
                  <div>0 KETH Points</div>
                </div>
              </div>
            
            </div>

            {!connectedAddress && (
                <>
                  <div className="overlayClaim"></div>
                  <div className="popupClaim">
                    <div className="popupClaim-btn" onClick={connecting}>Please connect your Wallet</div>
                    </div>
                </>
              )}
            </>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Claim;
