import React, { useContext, useEffect, useRef, useState } from "react";
import "./Stake.css";
import Mycontextprovider from "../ContextProvider";
const Stake = () => {
  const [activeButton, setActiveButton] = useState("stake");
  const [ShowDropdownFirst, setShowDropdownFirst] = useState(false);
  const [data, setData] = useState(null);
  const [DropdownImageFirst, setDropdownImageFirst] = useState();
  const [DropdownNameFirst, setDropdownNameFirst] = useState("ETH");
  const [dataSelected, setDataSelected] = useState();
  const [DropdownNameSecond, setDropdownNameSecond] = useState();
  const [DropdownImageSecond, setDropdownImageSecond] = useState();
  const [tokenBalanceEthBalance, setTokenBalanceEthBalance] = useState(null);
  const [InputChangeUnstake, setInputChangeUnstake] = useState();
  const [InputChange, setInputChange] = useState();
  const [ShowDropdownSecond, setShowDropdownSecond] = useState(false);

  const { metaMaskAddress, kaanchBalancePr, Isdisconnected,connectedAddress, chainIDpr } =
    useContext(Mycontextprovider);

  const chainName = "sepolia";
  // const chainName="eth"
  // mine  const apiKey1 ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImZmODEyNjVkLThmMjEtNDJkNC05ZmEyLTg0OThkMDU0YmQ3MiIsIm9yZ0lkIjoiMzQzNzY5IiwidXNlcklkIjoiMzUzMzkzIiwidHlwZUlkIjoiNWY5ZDVlY2QtMDQyNy00OGM4LTg3ODUtODFhZTIyZGNlNjNlIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY4OTg2NDIsImV4cCI6NDg0MjY1ODY0Mn0.dazBZyOcfXEUh3IPEWX0m1LEsMjCSh-AvhTjptzdF-I"; // Replace with your actual Moralis API key
  const apiKey1 =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImRmOTFjYjg2LTM2NzktNGFhZS05ZmIxLTE2NTk2OGQzZjM3NyIsIm9yZ0lkIjoiMjY1MzY4IiwidXNlcklkIjoiMjY5OTM0IiwidHlwZUlkIjoiY2U4YzM5ZGQtNmEwNi00ZWRjLTk2NTEtNWZiNDFiN2VlMjY5IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2OTU3OTE5NjgsImV4cCI6NDg1MTU1MTk2OH0.wvbrk7Gt8rWoaYG77z26I2YxnEUvfJr_EpNXIVyCx-Y"; // Replace with your actual Moralis API key

  //For Swapping Stake Unstake===========================>
  const swapStakeUnstake = () => {
    if (activeButton === "stake") {
      setActiveButton("unstake");
      setDropdownNameFirst("ETH");
      setDropdownImageFirst(`${process.env.PUBLIC_URL}/Image/eth-color.svg`);
    } else if (activeButton === "unstake") {
      setActiveButton("stake");
    }
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const toggleDropdownFirst = () => {
    setShowDropdownFirst(!ShowDropdownFirst);
  };
  const stakeRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (stakeRef.current && !stakeRef.current.contains(event.target)) {
      setShowDropdownFirst(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [stakeRef]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://bridge-pair.keth.com/");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        const filterdata2 = Object.keys(jsonData).filter((item) => {
          return item;
        });
        setData(jsonData);
        setDataSelected(jsonData.ETH);
        setDropdownNameFirst(filterdata2[0]);
        setDropdownImageFirst(`${process.env.PUBLIC_URL}/Image/eth-color.svg`);
        setDropdownNameSecond("KETH");
        setDropdownImageSecond(`${process.env.PUBLIC_URL}/Image/kaanch.svg`);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetch data function
    fetchData();
  }, []);

  const Check = async (val) => {
    try {
      const response = await fetch("https://bridge-pair.keth.com/");
      const jsonData = await response.json();
      // console.log("jsonData",jsonData)
      if (jsonData) {
        const filterdata = Object.keys(jsonData).filter((item) => {
          return item.toUpperCase() === val;
        });

        setDataSelected(jsonData[filterdata[0]]);
        setDropdownNameFirst(val);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //Eth balance
  const fetchEthBalance = async () => {
    try {
      const ethResponse = await fetch(
        ` https://deep-index.moralis.io/api/v2/${metaMaskAddress}/balance?chain=${chainName}`,
        {
          headers: {
            "x-api-key": apiKey1,
          },
        }
      );

      if (!ethResponse.ok) {
        throw new Error("Failed to fetch ETH balance data");
      }

      const ethJsonData = await ethResponse.json();
      setTokenBalanceEthBalance(Number(ethJsonData.balance) / 10 ** 18);
      // console.log("ethBalance", Number(ethJsonData.balance) / 10 ** 18);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Eth Token Balance
  const fetchTokenBalance = async (paramsAddr) => {
    try {
      const tokenResponse = await fetch(
        `https://deep-index.moralis.io/api/v2.2/wallets/${metaMaskAddress}/tokens?chain=${chainName}&exclude_spam=true&exclude_unverified_contracts=true&exclude_native=true`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            "x-api-key": apiKey1,
          },
        }
      );
      if (!tokenResponse.ok) {
        throw new Error("Failed to fetch token data");
      }
      const tokenJsonData = await tokenResponse.json();

      const tokenBalance = tokenJsonData.result.find(
        (token) => token.token_address === paramsAddr
      );

      if (tokenBalance) {
        setTokenBalanceEthBalance(Number(tokenBalance.balance) / 10 ** 18);
      } else {
        setTokenBalanceEthBalance(0);
      }
      console.log("ethBalance", Number(tokenBalance.balance) / 10 ** 18);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEthBalance();
  }, [metaMaskAddress]);

  useEffect(() => {
    balance();
  }, [DropdownNameFirst, metaMaskAddress, apiKey1]);

  function balance() {
    if (DropdownNameFirst === "ETH") {
      fetchEthBalance();
    } else {
      fetchTokenBalance();
    }
  }

  const handleMaxButtonClickStake = () => {
    // Set the input value to a maximum value
    setInputChange(tokenBalanceEthBalance);
  };

  const toggleDropdownSecond = () => {
    setShowDropdownSecond(!ShowDropdownSecond);
  };
  const stakeRef2 = useRef(null);
  const handleOutsideClick2 = (event) => {
    if (stakeRef2.current && !stakeRef2.current.contains(event.target)) {
      setShowDropdownSecond(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick2);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick2);
    };
  }, [stakeRef2]);
  const handleMaxButtonClickUnstake = () => {
    // Set the input value to a maximum value
    setInputChangeUnstake(kaanchBalancePr);
    // setInputChangeUnstake(2);
  };

  return (
    <div>
      {/* <=============main contain start=============> */}
      <div className="main-center">
        <div className="main-center-container">
          <div className="main-center-container-child">
            <div className="main-center-container-child-card">
              {/* ++++++++++++++++++++start1 */}
              <div className="fisrt-stake-container">
                <div className="fisrt-stake-container-heading">
                  <h1>1 Million Kaanch (KNCH) to claim</h1>
                </div>
                <div className="fisrt-stake-container-sec">
                  <button className="restake-btn">Restake</button>
                  <p style={{ fontSize: "15px;" }}>Get 18% Extra APR as KNCH</p>
                </div>
              </div>
              {/* ++++++++++++++++++++end1 */}

              {/* second-main constainer start2+++++++++++++ */}
              <div className="second-main-container">
                <div className="second-main-container-child-one">
                  <div className="second-main-container-child-one-first">
                    <h1>Restake</h1>
                    <div className="second-main-container-child-one-first-border">
                      <span
                        class="material-symbols-outlined"
                        style={{ color: "green" }}
                      >
                        verified
                      </span>
                      <span>Audited</span>
                    </div>
                  </div>
                  <div className="second-main-container-child-one-second">
                    <div style={{ display: "flex", gap: "2px" }}>
                      <span>APY</span>{" "}
                      <div className="icon-container">
                        <span
                          class="material-symbols-outlined icon-container"
                          style={{ fontSize: "13px", marginTop: "3px" }}
                        >
                          info
                        </span>

                        <div className="popupmain">
                          <div className="popup-content">
                            30 Day moving average
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>3.6%</div>
                  </div>
                  <div className="second-main-container-child-one-third">
                    <div>Total value</div>
                    <div>252,698.08 ETH | $823,886,708</div>
                  </div>
                  <div className="second-main-container-child-one-four">
                    <div className="second-main-container-child-one-four-child">
                      <div
                        className={`second-main-container-child-one-four-child-top ${
                          activeButton === "stake"
                            ? "second-main-container-child-one-four-child-active"
                            : ""
                        }`}
                        onClick={() => handleButtonClick("stake")}
                      >
                        Stake
                      </div>
                      <div
                        className={`second-main-container-child-one-four-child-top ${
                          activeButton === "unstake"
                            ? "second-main-container-child-one-four-child-active"
                            : ""
                        }`}
                        onClick={() => handleButtonClick("unstake")}
                      >
                        Unstake
                      </div>
                    </div>
                  </div>

                  {/* For stake  */}
                  {activeButton === "stake" && (
                    <>
                      <div className="second-main-container-child-one-five">
                        <div className="second-main-container-child-one-five-first">
                          Your Stake
                        </div>
                        <div className="second-main-container-child-one-five-second">
                          <input
                            className="second-main-container-child-one-five-second-input"
                            value={InputChange}
                            placeholder="0"
                            onChange={(e) => setInputChange(e.target.value)}
                          ></input>
                          <div className="second-main-container-child-one-five-second-dropdown">
                            <div
                              className="second-main-container-child-one-five-second-dropdown-drp"
                              ref={stakeRef}
                              onClick={toggleDropdownFirst}
                            >
                              <img
                                className="imgLogo"
                                alt=""
                                src={DropdownImageFirst}
                              />
                              {DropdownNameFirst}
                              {ShowDropdownFirst && (
                                <div className="second-main-container-child-one-five-second-dropdown-drp-content">
                                  {data &&
                                    data.methods.stake.map((val, i) => (
                                      <div
                                        key={i}
                                        className="second-main-container-child-one-five-second-dropdown-drp-content-item"
                                        onClick={() => {
                                          Check(val);

                                          if (val === "ETH") {
                                            setDropdownImageFirst(
                                              `${process.env.PUBLIC_URL}/Image/eth-color.svg`
                                            );
                                            fetchEthBalance();
                                          } else if (val === "STETH") {
                                            setDropdownImageFirst(
                                              `${process.env.PUBLIC_URL}/Image/steth-color.svg`
                                            );
                                            fetchTokenBalance(
                                              "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84"
                                            );
                                          } else if (val === "WETH") {
                                            setDropdownImageFirst(
                                              `${process.env.PUBLIC_URL}/Image/weth-color.svg`
                                            );
                                            fetchTokenBalance(
                                              "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
                                            );
                                          } else if (val === "KETH") {
                                            setDropdownImageFirst(
                                              `${process.env.PUBLIC_URL}/Image/kaanch.svg`
                                            );
                                          }
                                        }}
                                      >
                                        {val}
                                      </div>
                                    ))}
                               
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="second-main-container-child-one-five-third">
                          {metaMaskAddress && chainIDpr == "0xaa36a7" && (
                            <>
                              <div
                                style={{ color: "#8a0aaa" }}
                                className="max-btn"
                                onClick={handleMaxButtonClickStake}
                              >
                                Max
                              </div>
                              <div className="balance">
                                Balance: {tokenBalanceEthBalance}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="second-main-container-child-one-six">
                        {/* <span
                          class="material-symbols-outlined"
                          onClick={swapStakeUnstake}
                          style={{ cursor: "pointer" }}
                        >
                          swap_vertical_circle
                        </span> */}
                        <img
                          className="swap-btn active"
                          onClick={swapStakeUnstake}
                          src={`${process.env.PUBLIC_URL}/Image/refresh.png`}
                          alt="kaanch"
                        />
                      </div>

                      <div className="second-main-container-child-one-five">
                        <div className="second-main-container-child-one-five-first">
                          You Recieve
                        </div>
                        <div className="second-main-container-child-one-five-second">
                          <input
                            className="second-main-container-child-one-five-second-input"
                            placeholder="0"
                          ></input>
                          <div className="second-main-container-child-one-five-second-dropdown">
                            <div
                              className="second-main-container-child-one-five-second-dropdown-drp"
                              ref={stakeRef2}
                              onClick={toggleDropdownSecond}
                            >
                              <img
                                className="imgLogo"
                                alt=""
                                src={DropdownImageSecond}
                              />
                              {DropdownNameSecond}
                              {ShowDropdownSecond && (
                                <div className="second-main-container-child-one-five-second-dropdown-drp-content">
                                  {dataSelected &&
                                    dataSelected.pairs.map((val, i) => (
                                      <div
                                        key={i}
                                        className="second-main-container-child-one-five-second-dropdown-drp-content-item"
                                        onClick={() => {
                                          setDropdownNameSecond(val);

                                          if (val === "ETH") {
                                            // src={`${process.env.PUBLIC_URL}/Image/eth-color.svg`}
                                            setDropdownImageSecond(
                                              `${process.env.PUBLIC_URL}/Image/eth-color.svg`
                                            );
                                          } else if (val === "stETH") {
                                            setDropdownImageSecond(
                                              `${process.env.PUBLIC_URL}/Image/steth-color.svg`
                                            );
                                          } else if (val === "WETH") {
                                            setDropdownImageSecond(
                                              `${process.env.PUBLIC_URL}/Image/weth-color.svg`
                                            );
                                          } else if (val === "KETH") {
                                            setDropdownImageSecond(
                                              `${process.env.PUBLIC_URL}/Image/kaanch.svg`
                                            );
                                          }
                                        }}
                                      >
                                        {val}
                                      </div>
                                    ))}
                                
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="second-main-container-child-one-five-third">
                          
                        </div>
                      </div>

                      <div className="second-main-container-child-one-seven">
                        Kaanch will be available to Claim 10 days after
                        Unstaking
                      </div>

                      <div className="second-main-container-child-one-eight">
                        Stake
                      </div>
                    </>
                  )}

                  {/* For Unstake-------- */}
                  {activeButton === "unstake" && (
                    <>
                      <div className="second-main-container-child-one-five">
                        <div className="second-main-container-child-one-five-first">
                          Your Unstake
                        </div>
                        <div className="second-main-container-child-one-five-second">
                          <input
                            className="second-main-container-child-one-five-second-input"
                            value={InputChangeUnstake}
                            placeholder="0"
                            onChange={(e) =>
                              setInputChangeUnstake(e.target.value)
                            }
                          ></input>
                          <div className="second-main-container-child-one-five-second-dropdown">
                            <div
                              className="second-main-container-child-one-five-second-dropdown-drp"
                              onClick={toggleDropdownSecond}
                              ref={stakeRef2}
                            >
                              <img
                                className="imgLogo"
                                alt=""
                                src={DropdownImageSecond}
                              />
                              {DropdownNameSecond}
                              {ShowDropdownSecond && (
                                <div className="second-main-container-child-one-five-second-dropdown-drp-content">
                                  {data &&
                                    data.ETH.pairs.map((val, i) => (
                                      <div
                                        key={i}
                                        className="second-main-container-child-one-five-second-dropdown-drp-content-item"
                                        onClick={() => {
                                          setDropdownNameSecond(val);
                                          if (val === "ETH") {
                                            // src={`${process.env.PUBLIC_URL}/Image/eth-color.svg`}
                                            setDropdownImageSecond(
                                              `${process.env.PUBLIC_URL}/Image/eth-color.svg`
                                            );
                                          } else if (val === "stETH") {
                                            setDropdownImageSecond(
                                              `${process.env.PUBLIC_URL}/Image/steth-color.svg`
                                            );
                                          } else if (val === "WETH") {
                                            setDropdownImageSecond(
                                              `${process.env.PUBLIC_URL}/Image/weth-color.svg`
                                            );
                                          } else if (val === "KETH") {
                                            setDropdownImageSecond(
                                              `${process.env.PUBLIC_URL}/Image/kaanch.svg`
                                            );
                                          }
                                        }}
                                      >
                                        {val}
                                      </div>
                                    ))}
                                  {/* <div className="second-main-container-child-one-five-second-dropdown-drp-content-item">abcd</div>
                                  <div className="second-main-container-child-one-five-second-dropdown-drp-content-item">abcd</div>
                                  <div className="second-main-container-child-one-five-second-dropdown-drp-content-item">abcd</div> */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="second-main-container-child-one-five-third">
                          {connectedAddress&&(
                            <>
                            <div
                            style={{ color: "#8a0aaa" }}
                            className="max-btn"
                            onClick={handleMaxButtonClickUnstake}
                          >
                            Max
                          </div>
                          <div className="balance">
                            Balance: {kaanchBalancePr}
                          </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="second-main-container-child-one-six">
                        {/* <span
                          class="material-symbols-outlined"
                          onClick={swapStakeUnstake}
                          style={{ cursor: "pointer" }}
                        >
                          swap_vertical_circle
                        </span> */}
                        <img
                          className="swap-btn active"
                          onClick={swapStakeUnstake}
                          src={`${process.env.PUBLIC_URL}/Image/refresh.png`}
                          alt="kaanch"
                        />
                      </div>

                      <div className="second-main-container-child-one-five">
                        <div className="second-main-container-child-one-five-first">
                          You Recieve
                        </div>
                        <div className="second-main-container-child-one-five-second">
                          <input
                            className="second-main-container-child-one-five-second-input"
                            placeholder="0"
                          ></input>
                          <div className="second-main-container-child-one-five-second-dropdown">
                            <div
                              className="second-main-container-child-one-five-second-dropdown-drp"
                              onClick={toggleDropdownFirst}
                              ref={stakeRef2}
                            >
                              <img
                                className="imgLogo"
                                alt=""
                                src={DropdownImageFirst}
                              />
                              {DropdownNameFirst}
                              {ShowDropdownFirst && (
                                <div className="second-main-container-child-one-five-second-dropdown-drp-content">
                                  {data &&
                                    data.KETH.pairs.map((val, i) => (
                                      <div
                                        key={i}
                                        className="second-main-container-child-one-five-second-dropdown-drp-content-item"
                                        onClick={() => {
                                          setDropdownNameFirst(val);
                                          Check(val);
                                          if (val === "ETH") {
                                            // src={`${process.env.PUBLIC_URL}/Image/eth-color.svg`}
                                            setDropdownImageFirst(
                                              `${process.env.PUBLIC_URL}/Image/eth-color.svg`
                                            );
                                          } else if (val === "stETH") {
                                            setDropdownImageFirst(
                                              `${process.env.PUBLIC_URL}/Image/steth-color.svg`
                                            );
                                          } else if (val === "WETH") {
                                            setDropdownImageFirst(
                                              `${process.env.PUBLIC_URL}/Image/weth-color.svg`
                                            );
                                          } else if (val === "KETH") {
                                            setDropdownImageFirst(
                                              `${process.env.PUBLIC_URL}/Image/kaanch.svg`
                                            );
                                          }
                                        }}
                                      >
                                        {val}
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="second-main-container-child-one-five-third">
                          {/* <div style={{ color: "#8a0aaa" }}>Max</div>
                      <div>Balance: 0000000</div> */}
                        </div>
                      </div>

                      <div className="second-main-container-child-one-seven">
                        Kaanch will be available to Claim 10 days after
                        Unstaking
                      </div>

                      <div className="second-main-container-child-one-eight">
                        Stake
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* second-main constainer end2+++++++++++++ */}
            </div>
          </div>
        </div>
      </div>
      {/* <========main container end here */}

      {/* <=============Righ container=============> */}
      <div className="right-side">
        <div className="right-side-one">
          <p className="right-side-one-first">KETH Stats</p>
          <div className="right-side-one-second">
            <div className="right-side-one-second-row">
              <div>
                <img
                  className="img-rght-side"
                  src="https://i.pinimg.com/originals/fd/16/25/fd16259840bd8fda8251a4239750d8d8.jpg"
                />
              </div>
              <div className="right-side-one-second-row-name">
                <div>526M</div>
                <div>Total KETH Grand Miles</div>
              </div>
            </div>
            <div className="right-side-one-second-row">
              <div>
                <img
                  className="img-rght-side"
                  src="https://i.pinimg.com/originals/fd/16/25/fd16259840bd8fda8251a4239750d8d8.jpg"
                />
              </div>
              <div className="right-side-one-second-row-name">
                <div>526M</div>
                <div>Total KETH Grand Miles</div>
              </div>
            </div>
            <div className="right-side-one-second-row">
              <div>
                <img
                  className="img-rght-side"
                  src="https://i.pinimg.com/originals/fd/16/25/fd16259840bd8fda8251a4239750d8d8.jpg"
                />
              </div>
              <div className="right-side-one-second-row-name">
                <div>526M</div>
                <div>Total KETH Grand Miles</div>
              </div>
            </div>
            <div className="right-side-one-second-row">
              <div>
                <img
                  className="img-rght-side"
                  src="https://i.pinimg.com/originals/fd/16/25/fd16259840bd8fda8251a4239750d8d8.jpg"
                />
              </div>
              <div className="right-side-one-second-row-name">
                <div>526M</div>
                <div>Total KETH Grand Miles</div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-side-two">
          <div className="right-side-two-first">Assets Restake</div>
          <div className="right-side-two-sec">
            <div className="right-side-two-sec-one">
              <div className="right-side-two-sec-row">
                <div className="right-side-two-sec-row-fisrt">
                  <div>100.34K</div>
                  <span className="icon-container">
                    <span class="material-symbols-outlined icon">info</span>
                    <div className="popup">
                      <div className="popup-content">
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="right-side-two-sec-row-fisrt">
                  <img
                    className="img-rght-side-sec"
                    src="https://i.pinimg.com/originals/fd/16/25/fd16259840bd8fda8251a4239750d8d8.jpg"
                  />
                  <span> steth</span>
                </div>
              </div>
              <div className="right-side-two-sec-row">
                <div className="right-side-two-sec-row-fisrt">
                  <div>100.34K</div>
                  <span className="icon-container">
                    <span class="material-symbols-outlined icon">info</span>
                    <div className="popup2">
                      <div className="popup-content">
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="right-side-two-sec-row-fisrt">
                  <img
                    className="img-rght-side-sec"
                    src="https://i.pinimg.com/originals/fd/16/25/fd16259840bd8fda8251a4239750d8d8.jpg"
                  />
                  <span> steth</span>
                </div>
              </div>
            </div>
            <div className="right-side-two-sec-one">
              <div className="right-side-two-sec-row">
                <div className="right-side-two-sec-row-fisrt">
                  <div>100.34K</div>
                  <span className="icon-container">
                    <span class="material-symbols-outlined icon">info</span>
                    <div className="popup ">
                      <div className="popup-content">
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="right-side-two-sec-row-fisrt">
                  <img
                    className="img-rght-side-sec"
                    src="https://i.pinimg.com/originals/fd/16/25/fd16259840bd8fda8251a4239750d8d8.jpg"
                  />
                  <span> steth</span>
                </div>
              </div>
              <div className="right-side-two-sec-row">
                <div className="right-side-two-sec-row-fisrt">
                  <div>100.34K</div>
                  <span className="icon-container">
                    <span class="material-symbols-outlined icon">info</span>
                    <div className="popup ">
                      <div className="popup-content">
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                        <span className="popup-content1">
                          <span className="text-col">Deposited on KETH</span>
                          <span>2455675</span>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="right-side-two-sec-row-fisrt">
                  <img
                    className="img-rght-side-sec"
                    src="https://i.pinimg.com/originals/fd/16/25/fd16259840bd8fda8251a4239750d8d8.jpg"
                  />
                  <span> steth</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stake;
